.placeholder--container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .header {
    font-size: 30px;
    color: #373737;
    padding: 10px 0px;
  }
  .logo {
    width: 300px;
  }
  .follow {
    font-size: 20px;
    color: #373737;
    padding: 10px 0px;
  }
  .social--links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 0px;
  }
  .thanks--message {
    font-weight: 600;
    color: #373737;
    padding: 10px 0px;
  }
}
